import { parseError } from '../../../v3/presentation/shared/error';
import { toastError } from '../../../v3/presentation/shared/toast';
import { BasePresenter, EventWithType } from './base.presenter';

export type StatefulStateType = 'Loading' | 'Idle' | 'Error' | 'Success';

export type StatefulState<Data, Error = string> = {
  type: StatefulStateType;
  data?: Data;
  error?: Error;
};

/**
 * A Presenter is responsible for the Presentation logic
 * Here you will apply all the validations needs, invoke the Use Cases, prepare the View State and decide of any Side Effects
 * The Presenter is Framework Agnostic
 */
export abstract class StatefulPresenter<
  ExtendedViewState extends StatefulState<any, any>,
  ViewEvent extends EventWithType,
  SideEffect,
> extends BasePresenter<ExtendedViewState, ViewEvent, SideEffect> {
  /**
   * Default state
   */
  protected defaultState: StatefulStateType = 'Loading';

  /**
   * determine if the data has at least loaded once
   */
  public hasLoadedOnce = false;

  /**
   * Start loading
   */
  public startLoading(): void {
    this.merge({
      type: 'Loading',
      // data: undefined,
      error: undefined,
    } as ExtendedViewState);
  }

  /**
   * Mark the state as success
   */
  public success(data: ExtendedViewState['data']): void {
    this.hasLoadedOnce = true;
    this.merge({
      type: 'Success',
      data,
      error: undefined,
    } as ExtendedViewState);
  }

  /**
   * Mark the state as failed
   * For some cases, we might need to update the data as well
   */
  public failed(
    error: ExtendedViewState['error'],
    data: ExtendedViewState['data'] = undefined,
  ): void {
    this.merge({
      type: 'Error',
      error,
      data,
    } as ExtendedViewState);
  }

  /**
   * Toast failed message
   */
  public toastFailed(error: any, parse = true): void {
    if (parse) {
      error = parseError(error);
    }

    this.failed(error);
    toastError(error);
  }

  /**
   * Mark the state as idle
   */
  public idle(): void {
    this.merge({
      type: 'Idle',
      data: undefined,
      error: undefined,
    } as ExtendedViewState);
  }

  /**
   * Get a value from data object for the given key
   */
  public get data(): ExtendedViewState['data'] {
    return this.get('data');
  }

  /**
   * Set data value
   */
  public setData(data: ExtendedViewState['data']): void {
    this.set('data', data);
  }

  /**
   * Get a value from data object for the given key
   */
  public value<K extends keyof ExtendedViewState['data']>(key: K): ExtendedViewState['data'][K] {
    return this.get('data')[key];
  }

  /**
   * Get current error
   */
  public get error(): ExtendedViewState['error'] {
    return this.get('error');
  }

  /**
   * Check if current state is loading
   */
  public get isLoading(): boolean {
    return this.get('type') === 'Loading';
  }

  /**
   * Check if data is loaded
   */
  public get isLoaded(): boolean {
    return ['Success', 'Error'].includes(this.get('type'));
  }
}
